import { enableAppointment } from 'components/onboardingForm/utils'

const ENABLE_REFERRALFORM_INSURANCESECTION =
  process.env.ENABLE_REFERRALFORM_INSURANCESECTION

export const pageCollection = {
  referrerInformation: 'progress/referrer-information',
  patientInformation: 'progress/patient-information',
  serviceMatch: 'progress/service-matching',
  insurance: 'progress/insurance',
  finalize: 'progress/finalize',
  complete: 'complete',
  patientInfoVerification: 'progress/patient-information/verify',
  insuranceVerification: 'progress/insurance/verify',
  appointment: 'progress/appointment',
  review: 'progress/review',

  errorReview: 'progress/review/error',
}
  
export const referralFormRoutes = ENABLE_REFERRALFORM_INSURANCESECTION
  ? [
      { path: '*'  }, // start
      { path: pageCollection.referrerInformation },
      { path: pageCollection.patientInformation  },
      { path: pageCollection.serviceMatch  },
      { path: pageCollection.insurance  },
      { path: pageCollection.finalize  },
      { path: pageCollection.complete  },
    ]
  : [
      { path: '*'  }, // start
      { path: pageCollection.referrerInformation },
      { path: pageCollection.patientInformation  },
      { path: pageCollection.serviceMatch  },
      { path: pageCollection.complete  },
    ]

export type ReferralFormRoutePath = (typeof referralFormRoutes)[number]['path']

export const referralFormBasePath = '/patient-referral'

export const patientOnboardingFormRoutes = enableAppointment
  ? [
      { path: '*' }, // start
      { path: pageCollection.patientInformation },
      { path: pageCollection.patientInfoVerification },
      { path: pageCollection.serviceMatch },
      { path: pageCollection.insurance },
      { path: pageCollection.insuranceVerification },
      { path: pageCollection.appointment },
      { path: pageCollection.review },
      { path: pageCollection.complete },
      // extra pages
      { path: pageCollection.errorReview },
    ]
  : [
      { path: '*' }, // start
      { path: pageCollection.patientInformation },
      { path: pageCollection.patientInfoVerification },
      { path: pageCollection.serviceMatch },
      { path: pageCollection.insurance },
      { path: pageCollection.insuranceVerification },
      { path: pageCollection.review },
      { path: pageCollection.complete },
    ]

export const progress = (
  enableAppointment
    ? [
        patientOnboardingFormRoutes[1],
        patientOnboardingFormRoutes[3],
        patientOnboardingFormRoutes[4],
        patientOnboardingFormRoutes[6],
        patientOnboardingFormRoutes[7],
      ]
    : [
        patientOnboardingFormRoutes[1],
        patientOnboardingFormRoutes[3],
        patientOnboardingFormRoutes[4],
        patientOnboardingFormRoutes[6],
      ]
) as { path: string }[]

export type PatientOnboardingFormRoutePath =
  (typeof patientOnboardingFormRoutes)[number]['path']

export const patientOnboardingFormBasePath = '/patient-onboarding'

export const getStepByPath = (path: string, isReferral: boolean): number => {
  if (isReferral) {
    return referralFormRoutes
      .slice(1, referralFormRoutes.length - 1)
      .findIndex((r) => r.path === path)
  }
  switch (path) {
    case pageCollection.patientInformation:
    case pageCollection.patientInfoVerification:
      return 0
    case pageCollection.serviceMatch:
      return 1
    case pageCollection.insurance:
    case pageCollection.insuranceVerification:
      return 2
    case pageCollection.appointment:
      if (enableAppointment) return 3
      return -1
    case pageCollection.review:
      if (enableAppointment) return 4
      return 3
    case pageCollection.errorReview:
      if (enableAppointment) return 4
      return -1
    default:
      return -1
  }
}
