import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { api as endpoints } from './generated'
import { CustomErrorResponse, transferApptConflictErrorRes } from '../schedulingApiService'
import { RootStateWithPatientAuth, PATIENT_AUTH_REDUCER_KEY } from '../../patientAuth'

export const schedulingExternalApi = createApi({
  reducerPath: 'schedulingExternalApi',
  tagTypes: ['appointment'],
  endpoints,
  keepUnusedDataFor: 120,
  baseQuery: graphqlRequestBaseQuery<CustomErrorResponse>({
    url: `https://${process.env.SCHEDULING_API_DOMAIN}/graphql`,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithPatientAuth
      const accessToken = state[PATIENT_AUTH_REDUCER_KEY].session?.access_token
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
      return headers
    },
    customErrors: ({ message, stack, name, response }) => ({
      message,
      stack: stack || '',
      name,
      response,
    }),
  }),
}).enhanceEndpoints({
  endpoints: {
    createAppointmentExternal: {
      invalidatesTags: ['appointment'],
      transformErrorResponse: transferApptConflictErrorRes,
    },
    updateAppointmentExternal: {
      invalidatesTags: (res, err, req) => [
        { type: 'appointment' as const, id: req.id },
      ],
      transformErrorResponse: transferApptConflictErrorRes,
    },
  },
})

export type SchedulingExternalApiType = typeof schedulingExternalApi
