import { Tabs, Tab, SxProps, MenuItem } from '@mui/material'
import { useTranslation } from '@valerahealth/ui-translation'
import { SingleSelect } from '../../base';
import { Language } from '@mui/icons-material';

type Props = {
  languageOptions: { key: string; label: string }[]
  sx?: SxProps
}

export default function LanguageSelect({ languageOptions, sx }: Props) {
  const { i18n, t } = useTranslation()
  return (
    <Tabs
      aria-label={t('language')}
      value={i18n.resolvedLanguage}
      onChange={(e, value) => {
        i18n.changeLanguage(value)
      }}
      sx={{
        minHeight: [0, '3rem'],
        '& .MuiTab-root': {
          padding: ['.5rem', '.75rem 1rem'],
          minHeight: [0, '3rem'],
          minWidth: 0,
        },
        ...sx,
      }}
    >
      {languageOptions.map(({ label, key }) => (
        <Tab label={label} key={key} value={key} />
      ))}
    </Tabs>
  )
}

export function LanguageSelectV2({ languageOptions, sx }: Props) {
  const { i18n, t } = useTranslation()
  return (
    <SingleSelect
      size="small"
      value={i18n.resolvedLanguage}
      onChange={(value) => {
        i18n.changeLanguage(value)
      }}
      sx={{...sx}}
      startAdornment={<Language sx={{mr:'4px'}} />}
    >
      {languageOptions.map(({ label, key }) => (
        <MenuItem key={key} value={key}>
          {label}
        </MenuItem>
      ))}
    </SingleSelect>
  )
}

export type LanguageSelectProps = Props
