import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { api as endpoints, TimeSlotSearchExternalDocument } from './generated'
import { handleGQLErrors } from '../../../utils'
import * as Types from '../../../shared/generated.types'
import {
  EnhancedTimeSlotSearch,
  EnhancedTimeSlotSearchVariables,
  TimeSlotSearchAggregationType,
} from '..'
import {
  PATIENT_AUTH_REDUCER_KEY,
  RootStateWithPatientAuth,
} from '../../patientAuth'

const baseApi = createApi({
  reducerPath: 'availability',
  tagTypes: ['availability'],
  keepUnusedDataFor: 60,
  baseQuery: graphqlRequestBaseQuery({
    url: `https://${process.env.AVAILABILITY_API_DOMAIN}/graphql`,
    customErrors: handleGQLErrors,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithPatientAuth
      switch (api.endpoint) {
        default:
          // eslint-disable-next-line no-case-declarations
          const accessToken =
            state[PATIENT_AUTH_REDUCER_KEY].session?.access_token
          if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`)
          }
      }
      return headers
    },
  }),
  endpoints,
})
  .injectEndpoints({
    overrideExisting: true,
    endpoints: (build) => ({
      timeSlotSearch: build.query<
        EnhancedTimeSlotSearch,
        EnhancedTimeSlotSearchVariables
      >({
        query: ({ query, sort, aggregations, ...rest }) => ({
          document: TimeSlotSearchExternalDocument,
          variables: {
            query: JSON.stringify(query),
            sort: sort && JSON.stringify(sort),
            aggregations: aggregations && JSON.stringify(aggregations),
            ...rest,
          },
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    endpoints: {
      timeSlotSearch: {
        providesTags: (res) => {
          const results = res?.timeSlotSearch?.results
          return results
            ? results.map((r) => ({ type: 'availability', id: r.id }))
            : []
        },
        transformResponse: (res: Types.TimeSlotSearch) => ({
          timeSlotSearch: {
            ...res.timeSlotSearch,
            aggregations: res.timeSlotSearch.aggregations
              ? (JSON.parse(
                  res.timeSlotSearch.aggregations,
                ) as TimeSlotSearchAggregationType)
              : undefined,
          },
        }),
      },
    },
  })

const availabilityExternalReducer = {
  [baseApi.reducerPath]: baseApi.reducer,
}

export { baseApi as availabilityExternalApi, availabilityExternalReducer }
