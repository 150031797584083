/* eslint @typescript-eslint/no-shadow: 0 */
import {
  createTheme as muiCreateTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
  type Theme,
  type ThemeOptions,
} from '@mui/material'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import type {} from '@mui/x-data-grid/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { type ThemeProviderProps } from '@mui/material/styles/ThemeProvider'
import { removeAlpha, withAlpha } from './utils'
import { createStyled } from '@mui/system'

const vahRed = '#d1146c'
const vahTeal = '#317b8c'

declare module '@mui/material' {
  interface Palette {
    vah: {
      primary: string
      secondary: string
    }
    accent: Accent
    removeAlpha: typeof removeAlpha
    withAlpha: typeof withAlpha
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    vah?: {
      primary?: string
      secondary?: string
    }
    accent?: Accent
    providerGrid?: {
      background: string
    }
    removeAlpha: typeof removeAlpha
    withAlpha: typeof withAlpha
  }
}

const headingFontBase = {
  fontFamily: 'Work Sans,Noto Sans,Tahoma',
}
const fontLight = '#F9F9F7'

const DEFAULT_ELEVATION = 2

interface Accent {
  green: {
    main: string
    light: string
    mainBackground: string
    dark: string
    darkBackground: string
  }
  pink: {
    main: string
    light: string
    dark: string
    darkBackground: string
  }
  orange: {
    main: string
    mainBackground: string
  }
  blue: {
    light: string
    main: string
    dark: string
    mainBackground: string
    darkBackground: string
  }
  purple: {
    light: string
    dark: string
    darkBackground: string
  }
}

const accentBackgroundOpacity = 0.1

/** valera's raw default theme - exported separately to allow customization if needed, else just use the constructed theme export */
export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#3874D9',
      light: '#7FABFF',
      dark: '#004398',
      contrastText: fontLight,
    },
    secondary: {
      main: '#122480',
      light: '#4857B1',
      dark: '#000C62',
      contrastText: fontLight,
    },
    error: {
      main: '#D32F2F',
      light: '#FF6659',
      dark: '#9A0007',
      contrastText: '#fff',
    },
    warning: {
      main: '#DE7900',
      light: '#FFA940',
      dark: '#A54C00',
      contrastText: '#fff',
    },
    info: {
      main: '#3874D9',
      light: '#7FABFF',
      dark: '#004398',
      contrastText: '#fff',
    },
    success: {
      main: '#00A391',
      light: '#2FBFAB',
      dark: '#006B5E',
      contrastText: fontLight,
    },
    action: {
      active: 'rgba(0, 12, 98, 0.54)',
      hover: 'rgba(0, 12, 98, 0.04)',
      selected: 'rgba(0, 12, 98, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
    text: {
      primary: '#000C62',
      secondary: 'rgba(0, 12, 98, 0.6)',
      disabled: 'rgba(0, 12, 98, 0.38)',
    },
    divider: 'rgba(0, 12, 98, 0.12)',
    background: {
      paper: '#fff',
      default: '#fcfcfb',
    },
    accent: {
      green: {
        main: 'rgba(79,172,156, 1)',
        light: 'rgba(79,172,156, 1)', // NOT USED except as fallback
        dark: 'rgba(0, 105, 92, 1)',
        mainBackground: `rgba(79,172,156,${accentBackgroundOpacity})`,
        darkBackground: `rgba(0, 105, 92,${accentBackgroundOpacity})`,
      },
      pink: {
        main: '#E7ABCC', //NOT USED
        light: 'rgba(218, 123, 155, 1)',
        dark: 'rgba(234,69,57,1)', //NOT USED
        darkBackground: `rgba(234,69,57,${accentBackgroundOpacity})`,
      },
      orange: {
        main: 'rgba(247, 155, 79, 1)',
        mainBackground: `rgba(247, 155, 79,${accentBackgroundOpacity})`,
      },
      blue: {
        light: 'rgba(152, 208, 222, 1)',
        main: '#82B1FF', // NOT USED
        dark: 'rgb(0, 12, 98)', // NOT USED
        mainBackground: `rgba(140, 158, 255,${accentBackgroundOpacity})`,
        darkBackground: `rgba(0, 12, 98,${accentBackgroundOpacity})`,
      },
      purple: {
        light: '#9575CD',
        dark: 'rgba(109, 21, 69, 1)',
        darkBackground: `rgba(109, 21, 69, ${accentBackgroundOpacity})`,
      },
    },
    vah: {
      primary: vahTeal,
      secondary: vahRed,
    },
    removeAlpha,
    withAlpha,
  },
  typography: {
    fontFamily: 'Noto Sans,Work Sans,Tahoma',
    h1: headingFontBase,
    h2: headingFontBase,
    h3: headingFontBase,
    h4: headingFontBase,
    h5: headingFontBase,
    h6: headingFontBase,
    button: headingFontBase,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {},
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(1),
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(1),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          color: 'inherit',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: DEFAULT_ELEVATION,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: DEFAULT_ELEVATION,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-readOnly, &.MuiAutocomplete-readOnly *': {
            cursor: 'text !important',
            userSelect: 'auto !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-readOnly, &.MuiInputBase-readOnly *': {
            cursor: 'text !important',
            userSelect: 'auto !important',
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        slotProps: {
          toolbar: {
            quickFilterProps: {
              variant: 'outlined',
              size: 'small',
            },
          },
        },
        localeText: {
          filterValueAny: 'Any',
          filterValueTrue: 'Yes',
          filterValueFalse: 'No',
          toolbarColumns: '',
          toolbarFilters: '',
          toolbarDensity: '',
          toolbarExport: '',
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: (theme as Theme).palette.background.paper,
          '& .MuiDataGrid-row--disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            '&:hover': {
              backgroundColor: theme.palette.action.disabledBackground,
            },
            color: theme.palette.grey[700],
          },
          '& .wrapHeader .MuiDataGrid-colCellTitle': {
            overflow: 'hidden',
            lineHeight: '20px',
            whiteSpace: 'normal',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            paddingTop: '12px',
            paddingBottom: '12px',
          },
        }),

        iconButtonContainer: {
          '&:first-of-type': {
            order: 2,
          },
        },
        filterFormValueInput: {
          minWidth: '200px',
          width: 'auto',
        },
        filterFormColumnInput: {
          width: 'auto',
        },
        filterFormOperatorInput: {
          width: 'auto',
        },
        filterForm: {
          alignItems: 'start',
        },
        // @ts-ignore
        filterFormLogicOperatorInput: {
          height: '100%',
        },
        filterFormDeleteIcon: {
          marginTop: '1em',
        },
        toolbarContainer: ({ theme }) => ({
          backgroundColor: theme.palette.action.hover,
          gap: 1,
          borderTopRightRadius: theme.shape.borderRadius,
          borderTopLeftRadius: theme.shape.borderRadius,
          padding: theme.spacing(1),
        }),
      },
    },
  },
}

/** mui theme adjusted with valera design system */
export const theme = responsiveFontSizes(muiCreateTheme(themeOptions))

/** includes both responsiveFont and muiCreateTheme mixins */
export const createTheme = (themeOptions: ThemeOptions) =>
  responsiveFontSizes(muiCreateTheme(themeOptions))

/** Deprecated - Not in use */
export const careManagerTheme = muiCreateTheme()

export function ThemeProvider({
  children,
  theme: selectedTheme,
}: ThemeProviderProps) {
  return (
    <MuiThemeProvider theme={selectedTheme}>
      <CssBaseline />
      {selectedTheme === theme ? (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&family=Work+Sans:wght@300;400;500;700&display=swap"
            rel="stylesheet"
          />
        </>
      ) : null}
      {children}
    </MuiThemeProvider>
  )
}

export { createStyled }
