import { Navbar as BaseNavbar } from '@valerahealth/ui-components/layout'
import {
  Link,
  Box,
  SingleSelect,
  MenuItem,
  ClockIcon,
  useMediaQuery,
} from '@valerahealth/ui-components/base'
import {
  LanguageSelect,
  LanguageSelectV2,
} from '@valerahealth/ui-components/features'
import { languages, languagesShort } from 'locales'
import { Route, Routes } from 'react-router'
import { Progress } from 'routes/Progress'
import { enableAppointment, timezoneOptions } from 'components/onboardingForm/utils'
import { useReduxDispatch, useReduxSelector } from 'store'
import appSlice from 'store/appSlice'

export const timeZoneShort = {
  'America/Los_Angeles': 'PST',
  'America/Denver': 'MDT',
  'America/Phoenix': 'MST',
  'America/Chicago': 'CST',
  'America/New_York': 'EST',
}
export function TimeZoneSelect() {
  const timeZone = useReduxSelector((state) => state.app.onboarding.timeZone)
  const dispatch = useReduxDispatch()
  return (
    <SingleSelect
      size="small"
      value={timeZone}
      onChange={(value) => {
        dispatch(appSlice.actions.setTimeZone(value))
      }}
      sx={{ ml: '10px', p: '0px 0px 0px 6px' }}
      startAdornment={<ClockIcon sx={{ mr: '4px' }} />}
    >
      {timezoneOptions.map(({ value: key }) => (
        <MenuItem key={key} value={key}>
          {timeZoneShort[key]}
        </MenuItem>
      ))}
    </SingleSelect>
  )
}

export default function Navbar() {
  const legacyLanguageSelect = (
    <Box
      sx={{
        margin: '1rem auto 0 auto',
        maxWidth: (theme) => theme.breakpoints.values.md,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <LanguageSelect sx={{ margin: '0 1rem' }} languageOptions={languages} />
    </Box>
  )

  const legacyNavbar = (
    <BaseNavbar
      color="transparent"
      position="relative"
      elevation={0}
      sx={{ width: '100%' }}
      toolbarProps={{
        sx: {
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          borderBottomStyle: 'solid',
          borderBottomColor: 'ButtonShadow',
        },
      }}
    >
      <Link
        to="https://valerahealth.com"
        target="_blank"
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          ml: 2,
          width: '100%',
          height: 'auto',
          maxWidth: '15rem',
          pt: 2,
          pb: 2,
        }}
      >
        <img
          src="https://cdn.valerahealth.com/images/valera-health-logo-v2.svg"
          alt="Valera Health"
        />
      </Link>
      <Progress />
    </BaseNavbar>
  )

  const legacyHeader = legacyNavbar

  
  const matches = useMediaQuery('(min-width:900px)')
  const navbar = (
    <BaseNavbar
      color="transparent"
      position="relative"
      elevation={0}
      sx={{ width: '100%' }}
      toolbarProps={{
        sx: {
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          borderBottomStyle: 'solid',
          borderBottomColor: 'ButtonShadow',
        },
      }}
    >
      {matches && (
        <>
          <Link
            to="https://valerahealth.com"
            target="_blank"
            sx={{
              width: '240px',
              height: 'auto',
              pt: 2,
              pb: 2,
              mr: 'auto',
            }}
          >
            <img
              src="https://cdn.valerahealth.com/images/valera-health-logo-v2.svg"
              alt="Valera Health"
            />
          </Link>
          <Progress />
          <Box
            sx={{
              display: 'flex',
              width: '240px',
              height: '100%',
              pt: 2,
              pb: 2,
              ml: 'auto',
              justifyContent: 'right',
            }}
          >
            <LanguageSelectV2
              sx={{ ml: 'auto', p: '0px 0px 0px 6px' }}
              languageOptions={languagesShort}
            />
            {enableAppointment && <TimeZoneSelect />}
          </Box>
        </>
      )}
      {!matches && (
        <>
          <Link
            to="https://valerahealth.com"
            target="_blank"
            sx={{
              width: '240px',
              height: 'auto',
              pt: 2,
              pb: 2,
              mr: 'auto',
            }}
          >
            <img
              src="https://cdn.valerahealth.com/images/valera-health-logo-v2.svg"
              alt="Valera Health"
            />
          </Link>
          <Box
            sx={{
              display: 'flex',
              width: '240px',
              height: '100%',
              pt: 2,
              pb: 2,
              ml: 'auto',
              justifyContent: 'right',
            }}
          >
            <LanguageSelectV2
              sx={{ ml: 'auto', p: '0px 0px 0px 6px' }}
              languageOptions={languagesShort}
            />
            {enableAppointment && <TimeZoneSelect />}
          </Box>
        </>
      )}
    </BaseNavbar>
  )

  return (
    <Routes>
      <Route path="/patient-onboarding/*" element={navbar} />
      <Route path="/patient-referral/*" element={legacyHeader} />
      <Route path="/patient-files" element={legacyHeader} />
    </Routes>
  )
}
