import { useTranslation } from '@valerahealth/ui-translation'
import { useLocation, useMatch, matchRoutes } from 'react-router'
import {
  Chip,
  Typography,
  Stack,
} from '@valerahealth/ui-components'
import { useReduxSelector } from 'store'
import { OptionalOnboardingFormSection } from 'store/appSlice'
import { Check } from '@valerahealth/ui-components/icons'
import {
  referralFormBasePath,
  patientOnboardingFormBasePath,
  referralFormRoutes,
  patientOnboardingFormRoutes,
  getStepByPath,
  pageCollection,
  progress,
} from './routeConfig'

interface StepProps {
  label: string
  step: number
  currentStep: number
  optional?: boolean
}
function Step({ label, step, currentStep, optional }: StepProps) {
  return (
    <Stack
      display="flex"
      direction="row"
      gap={2}
      alignItems="center"
      sx={{ ml: '4px' }}
    >
      {currentStep > step && (
        <>
          <Check color="success" />
          <Typography variant="body1">{label}</Typography>
          {optional && <Typography>*</Typography>}
        </>
      )}
      {currentStep === step && (
        <>
          <Chip color="primary" size="small" label={step + 1} />
          <Typography variant="body1">{label}</Typography>
          {optional && <Typography>*</Typography>}
        </>
      )}
      {currentStep < step && (
        <>
          <Chip
            sx={{
              backgroundColor: (theme) => theme.palette.text.disabled,
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.text.disabled),
            }}
            size="small"
            label={step + 1}
          />
          <Typography color="text.disabled" variant="body1">
            {label}
          </Typography>
          {optional && <Typography color="text.disabled">*</Typography>}
        </>
      )}
    </Stack>
  )
}

export function Progress() {
  const { t } = useTranslation()
  const pathToNavLabelMapping: { [s: string]: string } = {
    [pageCollection.referrerInformation]: t(
      'PatientOnboarding.referrerInformationNavTitle',
    ),
    [pageCollection.patientInformation]: t(
      'PatientOnboarding.patientInformationNavTitle',
    ),
    [pageCollection.serviceMatch]: t('PatientOnboarding.serviceMatchingNavTitle'),
    [pageCollection.insurance]: t('PatientOnboarding.insuranceNavTitle'),
    [pageCollection.appointment]: t('PatientOnboarding.appointmentNavTitle'),
    [pageCollection.finalize]: t('PatientOnboarding.finalizeNavTitle'),
    [pageCollection.review]: t('PatientOnboarding.reviewNavTitle'),
  }
  const location = useLocation()
  const isReferralForm = !!useMatch(`${referralFormBasePath}/*`)
  const isPatientForm = !!useMatch(`${patientOnboardingFormBasePath}/*`)
  const optionalSections = useReduxSelector(
    (state) => state.app.onboarding.optionalOnboardingFormSections,
  )
  const match = isReferralForm
    ? matchRoutes(referralFormRoutes, location, referralFormBasePath)![0]!
    : isPatientForm
    ? matchRoutes(
        patientOnboardingFormRoutes,
        location,
        patientOnboardingFormBasePath,
      )![0]!
    : undefined

  if (!match) return null

  const currentStep = getStepByPath(match.route.path, isReferralForm)
  if (currentStep === -1) return null

  if (isReferralForm) {
    return (
      <Stack direction="row" gap={2}>
        {referralFormRoutes
          .slice(1, referralFormRoutes.length - 1)
          .map(({ path }, i) => (
            <Step
              key={pathToNavLabelMapping[path] || ''}
              label={pathToNavLabelMapping[path] || ''}
              step={i}
              currentStep={currentStep}
              optional={optionalSections?.includes(
                path as OptionalOnboardingFormSection,
              )}
            />
          ))}
      </Stack>
    )
  }

  if (isPatientForm) {
    return (
      <Stack direction="row" gap={2}>
        {progress.map(({ path }, i) => (
          <Step
            key={pathToNavLabelMapping[path] || ''}
            label={pathToNavLabelMapping[path] || ''}
            step={i}
            currentStep={currentStep}
            optional={optionalSections?.includes(
              path as OptionalOnboardingFormSection,
            )}
          />
        ))}
      </Stack>
    )
  }
  return null
}
